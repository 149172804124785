<template>
  <div style="padding-left:10px">
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
      <el-row :gutter="20">
        <el-col :span="5">
          <el-date-picker
              v-model="form.date"
              value-format="yyyy-MM"
              format="yyyy-MM"
              type="monthrange"
              start-placeholder="档期开始日期"
              end-placeholder="档期结束日期">
          </el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-select v-model="form.shopId" placeholder="选择店铺" clearable>
            <el-option
                v-for="item in shopIdArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select v-model="form.dressId" placeholder="选择礼服师" clearable>
            <el-option
                v-for="item in dressIdArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="search">搜索</el-button>
        </el-col>
      </el-row>
    </fieldset>
    <br>
    <el-collapse v-model="activeNames">
      <el-collapse-item title="店铺业绩" :name="1">
        <el-row>
          <el-col :span="4">
            <el-tooltip  class="item" effect="dark" :content='getAnnotation("售前业绩")'  placement="top-start">
              <p>售前业绩</p>
            </el-tooltip>
              <p>
                <el-popover placement="right" width="400" trigger="hover">
                  <el-table :data="suitSaleEarningGridData">
                    <el-table-column width="200" property="orderName" label="项目"></el-table-column>
                    <el-table-column width="200" property="orderPerformance" label="金额"></el-table-column>
                  </el-table>
                  <el-button slot="reference" style="font-size: 20px; border: none; padding: 0; margin: 0; background-color: transparent;">{{ tableDate.saleEarning === "" ? 0 : tableDate.saleEarning }}</el-button>
                </el-popover>
              </p>
          </el-col>
          <el-col :span="4">
            <el-tooltip  class="item" effect="dark"  :content='getAnnotation("售后业绩")'  placement="top-start">
              <p>售后业绩</p>
            </el-tooltip>
            <p>{{ tableDate.afterSaleEarning === "" ? 0 : tableDate.afterSaleEarning }}</p>
          </el-col>
          <el-col :span="4">
            <el-tooltip  class="item" effect="dark"  :content='getAnnotation("总业绩")' placement="top-start">
              <p>总业绩</p>
            </el-tooltip>
            <p>{{ tableDate.earning === "" ? 0 : tableDate.earning }}</p>
          </el-col>
        </el-row>
      </el-collapse-item>
      <el-collapse-item title="营收报告" :name="2">
        <el-row>
          <el-col :span="4">
            <el-tooltip class="item" effect="dark"  :content='getAnnotation("总营收")' placement="top-start">
              <p>总营收</p>
            </el-tooltip>
            <p>{{ tableDate.proceedsSum === "" ? 0 : tableDate.proceedsSum }}</p>
          </el-col>
          <el-col :span="4" v-if="proceedsAnalysis.length===0">
            <p>无</p>
            <p>0 </p>
          </el-col>
          <el-col :span="4" v-else  v-for="(item,index) in proceedsAnalysis.slice(0,5)" :key="index">
            <div v-if="item.sumMoney !== 0">
              <el-tooltip class="item" effect="dark" :content='getAnnotation(item.projectName)' placement="top-start">
                <p>{{ item.projectName }}</p>
              </el-tooltip>
                <p>{{ item.sumMoney }}</p>
            </div>
          </el-col>
        </el-row>
      </el-collapse-item>
      <el-collapse-item title="接待情况" :name="3">
        <el-row>
          <el-col :span="4">
            <el-tooltip class="item" effect="dark" :content='getAnnotation("售前接待数")' placement="top-start">
              <p>售前接待数</p>
            </el-tooltip>
            <p>{{ tableDate.saleReception === "" ? 0 : tableDate.saleReception }}</p>
          </el-col>
          <el-col :span="4">
            <el-tooltip class="item" effect="dark" :content='getAnnotation("一次订单数")' placement="top-start">
              <p>一次订单数</p>
            </el-tooltip>
            <p>{{ tableDate.onceTheOrder === "" ? 0 : tableDate.onceTheOrder }}</p>
          </el-col>
          <el-col :span="4">
            <el-tooltip class="item" effect="dark" :content='getAnnotation("一次未订单数")' placement="top-start">
              <p>一次未订单数</p>
            </el-tooltip>
            <p>{{ tableDate.saleReception - tableDate.onceTheOrder }}</p>
          </el-col>
<!--          <el-col :span="4">-->
<!--            <el-tooltip class="item" effect="dark" :content='getAnnotation("二次订单数")' placement="top-start">-->
<!--              <p>二次订单数</p>-->
<!--            </el-tooltip>-->
<!--            <p>{{ tableDate.twiceTheOrder === "" ? 0 : tableDate.twiceTheOrder }}</p>-->
<!--          </el-col>-->
          <el-col :span="4">
            <el-tooltip class="item" effect="dark" :content='getAnnotation("一次转化率")' placement="top-start">
              <p>一次转化率</p>
            </el-tooltip>
            <p>{{ tableDate.onceProportion }}</p>
          </el-col>
          <el-col :span="4">
            <el-tooltip class="item" effect="dark" :content='getAnnotation("综合转化率")' placement="top-start">
              <p>综合转化率</p>
            </el-tooltip>
            <p>{{ tableDate.comprehensiveProportion }}</p>
          </el-col>
        </el-row>
      </el-collapse-item>
      <el-collapse-item title="预约订单情况" :name="4">
        <el-row>
          <el-col :span="4">
            <el-tooltip class="item" effect="dark" :content='getAnnotation("售后接待数")' placement="top-start">
              <p>售后接待数</p>
            </el-tooltip>
            <p>{{ tableDate.afterSaleReception === "" ? 0 : tableDate.afterSaleReception }}</p>
          </el-col>
          <el-col :span="4">
            <el-tooltip class="item" effect="dark" :content='getAnnotation("售后升级接待数")' placement="top-start">
              <p>售后升级接待数</p>
            </el-tooltip>
            <p>{{ tableDate.afterSaleUpGradeReception === "" ? 0 : tableDate.afterSaleUpGradeReception }}</p>
          </el-col>
          <el-col :span="4">
            <el-tooltip class="item" effect="dark" :content='getAnnotation("售后订单数")' placement="top-start">
              <p>售后升级数</p>
            </el-tooltip>
            <p>{{ tableDate.afterSaleOrderNum === "" ? 0 : tableDate.afterSaleOrderNum }}</p>
          </el-col>
          <el-col :span="4">
            <el-tooltip class="item" effect="dark"  :content='getAnnotation("售后转化率")' placement="top-start">
              <p>售后转化率</p>
            </el-tooltip>
            <p>{{ tableDate.afterSaleProportion }}</p>
          </el-col>
        </el-row>
      </el-collapse-item>
      <el-collapse-item title="客资到店分析" :name="5">
        <el-row>
          <el-col :span="4" v-if="sourceArray.length===0">
            <p>无</p>
            <p>0 </p>
          </el-col>
          <el-col :span="4" v-else v-for="(item,index) in sourceArray.slice(0,8)" :key="index">
            <el-tooltip class="item" effect="dark"  :content='getAnnotation(item.sourceName)' placement="top-start">
              <p>{{ item.sourceName }}</p>
            </el-tooltip>
            <p>{{ item.sourceCount }}</p>
          </el-col>
        </el-row>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: "shop-earning-reports",
  data() {
    return {
      activeNames: [1, 2, 3, 4, 5],
      form: {
        date: this.$dateUtils.getMonthStrArray(),
        shopId: "",
        dressId: "",
        startDate: this.$dateUtils.getMonthStartEndDayStrArray()[0],
        endDate: this.$dateUtils.getMonthStartEndDayStrArray()[1]
      },
      shopIdArray: [],
      dressIdArray: [],

      tableDate: {
        //一次转化率
        onceProportion: 0,
        //综合转化率
        comprehensiveProportion: 0,
        //售后转化率
        afterSaleProportion:0,
      },
      sourceArray: [],
      proceedsAnalysis: [],
      annotations:[],
      //弹出框开关
      suitSaleEarningDialogTableVisible: false,
      suitSaleEarningGridData:[]
    }
  },
  created() {
    this.queryEarning();
    this.queryShopIdArray();
    this.queryDressIdArray();
    this.queryAnnotations();
  },
  methods: {
    search() {
      this.form.startDate = this.form.date ? this.form.date[0] + "-01" : "";
      this.form.endDate = this.form.date ? this.$dateUtils.getLastYmdByYmString(this.form.date[1]) : "";
      this.queryEarning();
    },
    //查询业绩
    queryEarning() {
      this.$axios({
        method: "GET",
        url: "/shopReports/queryShopEarningReports",
        params: {
          startDate: this.form.startDate,
          endDate: this.form.endDate,
          shopId: this.form.shopId,
          dressId: this.form.dressId,
          tenantCrop: localStorage.getItem("tenantCrop")
        }
      }).then(response => {
        this.tableDate = response.data.data[0];
        this.sourceArray = response.data.data[1];
        this.proceedsAnalysis = response.data.data[2];
        this.suitSaleEarningGridData = response.data.data[3];
        this.calculate();
      });
    },
    //计算比率
    calculate() {
      //一次转化率
      this.tableDate.onceProportion = (this.tableDate.onceTheOrder / this.tableDate.saleReception * 100).toFixed(2);
      this.tableDate.onceProportion = isNaN(this.tableDate.onceProportion) ? "0%" : this.tableDate.onceProportion + "%";
      //综合转化率
      this.tableDate.comprehensiveProportion = ((this.tableDate.twiceTheOrder + this.tableDate.onceTheOrder) / this.tableDate.saleReception * 100).toFixed(2);
      this.tableDate.comprehensiveProportion=isNaN(this.tableDate.comprehensiveProportion)?"0%":this.tableDate.comprehensiveProportion+"%";
      //售后转化率
      this.tableDate.afterSaleProportion=(this.tableDate.afterSaleOrderNum/this.tableDate.afterSaleReception*100).toFixed(2);
      this.tableDate.afterSaleProportion=isNaN(this.tableDate.afterSaleProportion)?"0%":this.tableDate.afterSaleProportion+"%";
    },
    //查询店铺
    queryShopIdArray() {
      this.$selectUtils.shopIdsIsValid().then(response => {
        this.shopIdArray = JSON.parse(response.data.data)
      })
    },
    //查询礼服师
    queryDressIdArray() {
      this.$selectUtils.queryDressIds().then(response => {
        this.dressIdArray = JSON.parse(response.data.data);
      })
    },
    //查询注解
    queryAnnotations() {
      this.$commUtils.queryAnnotation(this.$route.fullPath).then(response => {
        this.annotations = response.data.data;
      })
    },
    //查询对应字段的注解
    getAnnotation: function (field1) {
      for(let i  = 0; i <this.annotations.length; i++){
        if(this.annotations[i].field === field1){
          return this.annotations[i].annotation;
        }
      }},
  }
}
</script>

<style scoped>
p {
  font-size: 20px;
  margin: 3% 5%;
}
</style>